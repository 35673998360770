import type { Reroute } from '@sveltejs/kit';

const routeMap = {
  '/gift-cards': '/account/studio/gift-cards',
  '/gamerzclass': '/',
  '/account/chat': '/account',
  '/account/sessions': '/account/studio/sessions',
  '/account/settings': '/account/settings/about',
  '/account/settings/availability': '/account/studio/availability',
  '/account/settings/discounts': '/account/studio/discounts',
  '/account/settings/sessions': '/account/studio/sessions/settings',
  '/account/settings/reviews': '/account/studio/reviews'
};

export const reroute: Reroute = ({ url }) => {
  if (url.pathname.startsWith('/courses')) {
    return url.pathname.replace(/^\/courses/, '/guides');
  } else if (url.pathname.startsWith('/groups')) {
    return url.pathname.replace(/^\/groups/, '/communities');
  } else if (url.pathname in routeMap) {
    return routeMap[url.pathname];
  }
};
